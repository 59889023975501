<template>

  <div class="wrapper">
    <div class="animated fadeIn">
      <!-- 판매관리 --------------------------------------------------------------------------------------------->
      <BCard>
        <BCard class="mb-1" footer-tag="footer" header-tag="header">

          <div slot="header">
            <BIconGiftFill/>
            <strong> 패키지 상품관리 </strong>
            <div class="card-header-actions">
              <small class="text-muted">패키지 판매 상품을 생성/관리 합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol>
              <!--                            <strong>Total Records: {{ rows.length }}</strong>-->
              <BInputGroup>
                <BInputGroupAppend>
                  <BButton size="sm" variant="warning" @click="insertSalesMaster">
                    <BIconPlusCircleFill/>
                    패키지 상품 추가
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol sm="6">

            </BCol>
            <BCol sm="3" class="text-right">
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getSalesMasterList">
                  <BIconArrowRepeat/>
                  Refresh
                </BButton>
                <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                  <BIconFileSpreadsheetFill/>
                  다운로드
                </BButton>
              </BButtonGroup>
            </BCol>

          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                  ref="excelGrid"
                  v-model="rows"
                  :localized-label="$localizedLabel"
                  :page="numOfRecord"
                  :readonly-style="{backgroundColor:'#EFE'}"
                  class="mb-1"
                  filter-row
                  width="100%"
                  @select="selectSalesMaster"
                  @update="updateSalesMaster">
                <vue-excel-column field="_id" key-field label="ID" type="string" width="100px" readonly invisible/>
                <vue-excel-column field="code" label="판매코드" type="string" width="80px"/>
                <vue-excel-column field="name" label="판매명" width="200px"/>
                <vue-excel-column field="title" label="제목" width="200px"/>
                <vue-excel-column field="content" label="상세내용" width="300px"/>
                <vue-excel-column field="comment" label="비고" width="200px"/>
<!--                <vue-excel-column field="url" label="URL" :link="gotoUrl" width="150px" invisible/>-->
                <vue-excel-column field="image" label="상품이미지" :link="showImage" width="100px"/>
                <vue-excel-column field="products" label="판매제품" width="200px" readonly/>
                <vue-excel-column field="price" label="금액" type="number" width="80px" readonly/>
                <vue-excel-column field="discountRate" label="할인율" readonly type="number" width="80px" invisible/>
                <vue-excel-column field="discountAmount" label="할인금액" type="number" width="80px" readonly/>
                <vue-excel-column field="amount" label="판매금액" type="number" width="80px" readonly/>
                <vue-excel-column field="pubDt" label="게시일" type="date" width="100px"/>
                <vue-excel-column field="endDt" label="종료일" type="date" width="100px"/>
                <vue-excel-column field="useYn" label="판매상태" type="map" :options="{Y:'판매' , N:'중지'}" />
              </vue-excel-editor>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <BButtonGroup>

                <BButton variant="danger" @click="deleteSalesMaster">
                  <BIconTrashFill/>
                  상품삭제
                </BButton>
              </BButtonGroup>
            </BCol>

            <BCol>
              <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
            </BCol>
          </BRow>
        </BCard>

        <BCard class="mt-2" v-if="master!==null">
          <div slot="header">
            <BIconArchiveFill/> <strong> 판매상품 정보 <BBadge class="ml-3" variant="dark">{{master.code}}</BBadge> </strong>
            <div class="card-header-actions">
              <small class="text-muted">판매 상품 상세내용</small>
            </div>
          </div>

          <BRow>
            <BCol sm="8">
              <BTable small stacked :items="[master]" :fields="masterFields">
                <template #cell(code)>
                  <div class="df">
                    <BFormInput size="sm" v-model="master.code" :disabled="master.code === 'SINGLE-ALL' " />
                    <Tooltip :text="codeTooltip" style="align-content: center;">
                      <BIconQuestionCircleFill />
                    </Tooltip>
                  </div>
                </template>

                <template #cell(name)>
                  <BFormInput size="sm" v-model="master.name" placeholder="관리할 상품명을 입력해주세요."></BFormInput>
                </template>

                <template #cell(title)>
                  <div class="df">
                    <b-form-textarea size="sm" v-model="master.title" row="3" :placeholder="titleTooltip"></b-form-textarea>
                    <Tooltip :text="titleTooltip" style="align-content: center;">
                      <BIconQuestionCircleFill />
                    </Tooltip>
                  </div>
                </template>

                <template #cell(content)>
                  <div class="df">
                    <b-form-textarea size="sm" v-model="master.content" row="3" :placeholder="contentTooltip"></b-form-textarea>
                    <Tooltip :text="contentTooltip" style="align-content: center;">
                      <BIconQuestionCircleFill />
                    </Tooltip>
                  </div>
                </template>

                <template #cell(comment)>
                  <BFormInput size="sm" v-model="master.comment" placeholder="관리자가 해당 상품에 메모할 내용"></BFormInput>
                </template>

                <template #cell(image)>
                  {{master.image}}
                  <BInputGroup>
                  <BFormFile size="sm" accept="image/*" v-model="imgFile" @change="uploadImage"/>
                  <BButton size="sm" variant="warning" @click="uploadImageData" v-show="imgFile!=null">선택파일 업로드</BButton>
                  </BInputGroup>
                </template>

                <template #cell(products)>
                  <BInputGroup>
                    <BFormSelect size="sm" v-model="product" :options="prdOpts"/>
                    <BInputGroupAppend>
                      <BButton size="sm" variant="primary" @click="setProducts('A')" :disabled="product===null">
                        <BIconPlusCircleFill/>
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>
                  <BTableSimple class="mb-1 small">
                    <BTr v-for="r in master.products" :key="r.prd_code">
                      <BTd>{{r.prd_code}}</BTd>
                      <BTd>{{r.prd_name}}</BTd>
                      <BTd class="text-right">{{r.warranty_mon}} 개월</BTd>
                      <BTd class="text-right">{{comma(r.unit_price)}} 원</BTd>
                      <BTd class="text-right">
                        <BButton size="sm" variant="warning" @click="setProducts('D', r.prd_code)">
                          <BIconTrashFill/>
                        </BButton>
                      </BTd>
                    </BTr>
                  </BTableSimple>
                </template>
<!--                <template #cell(url)>
                  <BFormInput size="sm" v-model="master.url"></BFormInput>
                </template>-->

                <template #cell(discountAmount)>
                  <BFormInput v-model="master.discountAmount" />
                </template>

<!--                <template #cell(discountRate)>-->
<!--                  <span>{{ master.discountRate }}</span>-->
<!--                </template>-->

                <!-- 실제 판매가격 -->
                <template #cell(amount)>
                  <strong>{{ comma(master.amount) }}원</strong>
                  <span class="ml-2" style="color: #ff4d4d;">* 실제 판매가격</span>
                </template>

                <template #cell(pubDt)>
                  <date-picker v-model="pubDate" type="datetime" format="YYYY-MM-DD HH:mm:ss" />
                </template>
                <template #cell(endDt)>
                  <date-picker v-model="endDate" type="datetime" format="YYYY-MM-DD HH:mm:ss" />
                </template>

                <template #cell(sort)>
                  <BFormInput v-model="master.sort" type="number" />
                </template>

                <template #cell(accountLimited)>
                  <div class="df">
                    <BFormInput v-model="master.accountLimited" type="number" />
                    <Tooltip :text="accountLimitedTooltip" style="align-content: center;">
                      <BIconQuestionCircleFill />
                    </Tooltip>
                  </div>
                </template>

                <template #cell(qtyLimited)>
                  <div class="df">
                    <BFormInput v-model="master.qtyLimited" type="number" />
                    <Tooltip :text="qtyLimitedTooltip" style="align-content: center;">
                      <BIconQuestionCircleFill />
                    </Tooltip>
                  </div>
                </template>

                <template #cell(useYn)>
                  <BFormSelect size="sm" v-model="master.useYn"
                               :options="[{value:'Y', text: '판매'},{value:'N', text: '중지'} ]"/>
                </template>

                <template #cell(button)>
                  <BButton class="btn-howcost" @click="updateSalesMasterOne" block :disabled="isUpdating">
                    <strong>변경 사항 저장</strong>
                  </BButton>
                </template>
              </BTable>
            </BCol>

            <BCol>
              <img v-show="master.image" :src="`${baseURL}/images/${master.image}`" width="100%"/>
            </BCol>
          </BRow>

        </BCard>


      </BCard>

    </div>
  </div>
</template>


<script>
//-------------------------------------------------------------------------------------------------
import {
  alertSync,
  alertConfirm,
  apiCall,
  cloneVar,
  commify,
  toastSync,
  alertWarn,
  apiMultiPart, alertSuccess, dateToUtc, utcToDate
} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";
import Tooltip from "@/xcost/home/components/Tooltip.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

let _master = {
  code: '',
  name: '',
  title: '',
  content: '',
  comment: '',
  url: '',
  image: '',
  products: [],
  price: 0,
  discountRate: 0,
  discountAmount: 0,
  amount: 0,
  pubDt: null,
  endDt: null,
  useYn: 'N',
};

const columnMap = {
  code: '판매코드',
  name: '판매명',
  title: '판매상세',
  content: '판매내용',
  comment: '비고',
  url: '판매URL',
  image: '이미지',
  products: '판매제품',
  price: '금액',
  discountRate: '할인율',
  discountAmount: '할인금액',
  amount: '판매가',
  pubDt: '게시일',
  endDt: '종료일',
  useYn: '사용',
};



//----------------------------------------------------------------------------------------------------
export default {
  name: 'SalesMaster',
  components: {
    Tooltip,
    DatePicker
  },
  data() {
    return {
      product: null,
      imgFile: null,
      prdCodeOpts: {},
      selectedPrd: null,
      prdOpts: [],
      prdInfo: {},
      searchOpts: [
        {value: 'name', text: '이름/회사명'},
        {value: 'comp_name', text: '회사명'},
        {value: 'email', text: '전자우편'},
        {value: 'phone_no', text: '전화번호'},
        {value: 'cu_id', text: '아이디'},
        {value: 'prd_code', text: '제품코드'}
      ],
      dayOpts: [
        {value: 7, text: '1주일'},
        {value: 14, text: '2주일'},
        {value: 30, text: '30일'},
        {value: 90, text: '90일'},
        {value: 180, text: '180일'},
        {value: null, text: '전체'},
      ],
      dateGbn: "ord",
      dateGbnOpt: [
        {value: 'ord', text: '주문일'}
        , {value: 'appr', text: '승인일'}
        , {value: 'pay', text: '결제일'}
        , {value: 'send', text: '전송일'}
        , {value: 'tax', text: '계산서발행일'}
        , {value: 'reg', text: '등록일'}
        , {value: 'upd', text: '수정일'}
      ],
      payTypeOpts: {
        '9999': '주문생성',
        '0000': '결제대기',
        '1000': '현금(계좌)',
        '0001': '쿠폰',
        '0002': '바우처',
        '0010': '포인트',
        '0011': '포인트|쿠폰',
        '0100': '카드',
        '0101': '카드|쿠폰',
        '0110': '카드|포인트',
        '0111': '카드|포인트|쿠폰',
        '2000': '후불',
        '1001': '현금|쿠폰',
        '1010': '현금|포인트',
        '1011': '현금|포인트|쿠폰',
        '1100': '현금|카드',
        '1101': '현금|카드|쿠폰',
        '1110': '현금|카드|포인트',
        '1111': '현금|카드|포인트|쿠폰',
      },
      taxStatOpts: {
        '99': '미발행',
        '01': '영수계산서대상',
        '02': '청구계산서대상',
        '03': '현금영수증대상',
        '04': '영수증발행대상',
        '11': '영수발행',
        '12': '청구발행',
        '13': '현금영수증발행',
        '14': '영수증발행'
      },
      payStatOpts: {'99': '미결제', '10': '부분결제', '20': '초과결제', '00': '결제완료'},
      sendStatOpts: {'99': '미발송', '10': '구매확인', '20': '발송준비', '90': '발송에러', '00': '발송완료'},

      statusOpt: {'00': '활성', '10': '준비', '90': '추출', '99': '만료'},
      logTypeOpts: {'I': '접속', 'O': '종료', 'S': '설치'},
      progCount: 0,
      totalCount: 0,

      rows: [],
      claimLogs: [],
      selectedIdx: null,
      selectedRow: null,
      fromDate: moment().format("YYYY-MM-01"),
      toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

      // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      /////////////////////////////////////////////////////
      searchWord: null,
      searchField: 'name',
      paramMap: {},
      pubDate: '',
      endDate: '',

      numOfRecord: 20,
      master: null,
      masterFields: [
        { key: 'code'    , label: '판매코드'},
        { key: 'name'    , label: '판매명'},
        { key: 'title'    , label: '제목'},
        { key: 'content'  , label: '내용'},
        { key: 'comment' , label: '비고'},
        // { key: 'url'     , label: '판매 URL'},
        { key: 'image'   , label: '이미지'},
        { key: 'products', label: '판매제품'},
        { key: 'price'   , label: '제품 합계금액', formatter: v=>{return commify(v)}},
        { key: 'discountAmount' , label: '할인금액', formatter: v=>{return commify(v)}},
        { key: 'discountRate'   , label: '할인율', formatter: v=>{return (v) ? `${v}%`:'0%'}},
        { key: 'amount', label: '판매가', formatter: v=>{return commify(v)}},
        { key: 'pubDt', label: '게시일', formatter: v=>{  return moment(v).format('YYYY-MM-DD HH:mm')}},
        { key: 'endDt', label: '종료일', formatter: v=>{  return moment(v).format('YYYY-MM-DD HH:mm')}},
        { key: 'sort' , label: '노출순서'},
        { key: 'accountLimited' , label: '계정 한정수량'},
        { key: 'qtyLimited' , label: '상품 한정수량'},
        { key: 'useYn', label:'판매상태'},
        { key: 'button', label:'　'},
      ],
      baseURL: $baseURL,
      isUpdating: false,
      codeTooltip: `
        [판매코드 명명규칙]<br>
        - 다른 상품과 판매코드가 중복되지 않아야 합니다.<br>
        - [S] SALES, [P] PACKAGE, [E] EVENT 등 앞 대표 이니셜과 날짜를 조합한 형식입니다.<br>
        　ex) P241112 = 패키지 24년 11월 12일
      `,
      titleTooltip: `사용자에게 표시되는 상품명 입니다.`,
      contentTooltip: `사용자가 구매시 참고할 수 있는 내용입니다.<br>HTML태그를 사용할 수 있습니다.`,
      accountLimitedTooltip: `
        [계정 한정수량 판매]<br>
        - 계정당 구매할 수 있는 수량을 제한합니다.<br>
        - 0으로 설정할 시 제한없음으로 설정됩니다.
      `,
      qtyLimitedTooltip: `
        [상품 한정수량 판매]<br>
        - 상품당 구매할 수 있는 수량을 제한합니다.<br>
        - 0으로 설정할 시 제한없음으로 설정됩니다.
      `,
    }

  },
  watch: {
    'master.discountAmount': 'amountCalc'
  },
  async created() {
    try {
      this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      await this.getPrdCode();
      await this.getSalesMasterList();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("mounted---------------------ProductToken");
    // below is not work!
  },

  methods: {
    comma(v){
      return commify(v)
    },
    setProducts( cmd, prd_code=null ){
      if( cmd==='D' ){
        this.master.products = this.master.products.filter(i=> {
          return i.prd_code !== prd_code;
        });

      }else if( cmd==='A'){
        this.master.products = this.master.products.filter(i=> {
          return i.prd_code !== this.product.prd_code;
        });
        this.master.products.push( this.product );
      }

      let total = 0;
      for( let prd of this.master.products){
        total += prd.unit_price;
      }
      this.master.price = total;
      this.master.amount = total;
    },

    gotoUrl(url){
      window.open(url, "_blank");
    },

    async showImage(imgFile){
      window.open( `${$baseURL}/images/${imgFile}`, "_blank");
    },

    setPayDate() {
      // this.toDate =  moment(this.fromDate).endOf("month").format("YYYY-MM-DD");
      this.paramMap['dateGbn'] = this.dateGbn;
      this.paramMap['toDate'] = this.toDate;
      this.paramMap['fromDate'] = this.fromDate;
      // this.getSalesOrderList();
    },

    async getPrdCode() {
      console.log("getPrdCode ---- 1");
      this.prdCodeOpts = {};
      this.prdOpts = [];
      this.prdOpts.push({value: null, text: '주문상품 선택'});
      try {
        const r = await apiCall('get', `/admin/api/product/info`);

        r.result.map(i => {
          this.prdCodeOpts[i.prd_code] = i.disp_name;
          this.prdInfo[i.prd_code] = i;
          this.prdOpts.push({value: i, text: i.prd_code + ' | ' + i.disp_name, disabled: (i.use_yn !== "Y")});
        });
        // console.log("prdCodeOpts ----> ", this.prdCodeOpts);
        // console.log("prdOpts ----> ", this.prdOpts);
        // console.log("prdInfo ----> ", this.prdInfo);

      } catch (err) {
        console.log(err);
      }
    },

    async getSalesMasterList() {
      this.$refs['excelGrid'].clearFilter();
      this.master = null;
      let r = null;
      let qry = '';
      if (this.searchWord !== null) this.paramMap[this.searchField] = this.searchWord.toUpperCase();
      qry = qs.stringify(this.paramMap);
      console.log("query-string ---------- ", qry);
      try {
        this.rows = [];
        r = await apiCall('get', `/admin/api/sales/master?${qry}`);
        // console.log( "getSalesOrderList ----------> ", r);
        if (r.result) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }

      } catch (err) {
        console.log(err);
      }

    },
    setDateGbn() {
      this.paramMap['dateGbn'] = this.dateGbn;
      // this.getSalesOrderList();
    },

    exportExcel() {
      const format = 'xlsx';
      const exportSelectedOnly = true;
      const filename = 'sales-master-' + moment().format('YYYY-MM-DD');
      this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
    },


    async selectSalesMaster(idx, evt) {

      if (!evt) {
        this.master = null;
        return;
      }

      if(this.$refs['excelGrid'].getSelectedRecords().count > 1) return;
      // console.log( "selectProductToken --------> ", evt );
      //console.log( "selectSalesOrder --------> ", this.$refs['excelGrid'].getSelectedRecords() );

      this.master = this.$refs['excelGrid'].getSelectedRecords()[0];

      this.pubDate = utcToDate(this.master.pubDt);
      this.endDate = utcToDate(this.master.endDt);
      console.log(" 그리드 ROW 선택 되어 실행됨 ");
      // console.log("selectSalesMaster this.master ==========> ", this.master)
    },

    uploadImage(e){
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
        this.previewImage = e.target.result;
        // console.log(this.previewImage);
      };
    },
    async uploadImageData(){
      let formData = new FormData();
      // for( let file of this.imgFile ){
      // }
      formData.append("images", this.imgFile);

      try{
        const r = await apiMultiPart(`/admin/api/sales/image/${this.master._id}`, formData);
        console.log("apiMultiPart ----------------> result---------------");
        console.log( r );

        if( r.code===200){
          await alertSuccess( this.$bvModal, "이미지가 저장 되었습니다.", "파일 업로드");
          this.master = r.result;
        }else{
          await alertWarn( this.$bvModal, `Message: ${r.message}`, "파일 업로드 실패");
        }
      }catch(err){
        console.log(err);
      }finally{
        this.isItemLoading = false;
      }

    },

    validateCell(val, oldVal, rec, field) {
      console.log("----------------validateCell-------------------");
      console.log("validateCell---value ---> ", val);
      console.log("validateCell---oldVal ---> ", oldVal);
      console.log("validateCell---rec ---> ", rec);
      console.log("validateCell---field ---> ", field);
      // console.log( "validateCell---",this.$refs['excelGrid'] );
      // console.log( "validateCell---",this.$refs['excelGrid'].selectRecord(0) );
    },

    async insertSalesMaster() {
      let r = null;
      let rec = cloneVar(_master);

      rec.code = moment().format('YYMMDD');
      rec.name = '판매명 입력';
      rec.title = '표시될 판매 제목 입력';
      rec.content = '표시될 판매 내용 입력';
      rec.comment = '코멘트 입력';
      rec.pubDt = moment().toISOString();
      rec.endDt = moment().add(5, 'years').toISOString();

      try {
        r = await apiCall('POST', `/admin/api/sales/master`, rec);
        if (r.code===200) {
          await this.getSalesMasterList();
        }else{
          await alertWarn( this.$bvModal, `Message: ${r.message}`, "판매 상품 등록 실패");
        }
      } catch (err) {
        console.log(err);

      }

    },
    async updateSalesMasterOne() {
      this.master.pubDt = dateToUtc(this.pubDate);
      this.master.endDt = dateToUtc(this.endDate);
      // console.log("updateSalesMasterOne this.master ==========> ", this.master)
      // return;
      let r = null;

      try {
        let param = this.master;

        const objectId = this.master._id;

        r = await apiCall("PUT", `/admin/api/sales/master/${objectId}`, param);

        if( r.code===200 ) {
          await toastSync(this.$bvToast, `${param.code} 판매상품 정보 수정됨`, 'warning');
          await this.getSalesMasterList();
        }else{
          await toastSync( this.$bvToast, `${param.code} 수정 에러: ${r.message}`);
        }
        console.log(r);

      } catch (err) {
        console.log(err);
      }
    },

    async updateSalesMaster(rec) {
      let rc = rec[0];
      if (!rc.keys[0]) {
        console.log("nokey");
        return;
      }
      const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      console.log("updateSalesMaster--------- rec[0]|rc --->", rc);
      console.log("updateSalesMaster--------- rec[0].$id--->", rc.$id);
      console.log("updateSalesMaster--------- rec[0].$id--->", this.$refs['excelGrid']);
      console.log("updateSalesMaster--------- rowIndex--->", tIndex);
      let row = this.$refs['excelGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null;

      try {
        let param = {};

        const fieldName = rc.name;
        let newVal = rc.newVal;

        if (fieldName === 'products' || fieldName === 'code' || fieldName === 'price' || fieldName === 'discountRate' || fieldName === 'amount') {
          await toastSync(this.$bvToast, `${columnMap[fieldName]} 필드는 수정이 불가능합니다.`, 'warning');
          await this.getSalesMasterList();
          return ;
        }

        const objectId = rc.keys[0];
        param[fieldName] = newVal;

        r = await apiCall("PUT", `/admin/api/sales/master/${objectId}`, param);

        if( r.code===200 ) {
          await toastSync(this.$bvToast, `${columnMap[fieldName]} 수정됨`, 'warning');
        }else{
          row[fieldName] = rc.oldVal;
          await toastSync( this.$bvToast, `${columnMap[fieldName]} 수정 에러: ${r.message}`);
        }


        console.log(r);


      } catch (err) {
        console.log(err);
      }
    },

    async deleteSalesMaster(recs) {
      // let r = null, msg = null, notice = null;

      try {
        console.log("deleteSalesMaster recs ----> ", recs );
        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        console.log("deleteSalesMaster params ----> ", params);
        console.log("deleteSalesMaster length ----> ", params.length);

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할수 없습니다. 진행 하시겠습니까?`;

        if (!(await alertConfirm(this.$bvModal, confirmMsg, '판매상품 삭제'))) {
          return;
        }

        // this.$refs['excelGrid'].deleteSelectedRecords();
        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/admin/api/sales/master/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }
        }

        await alertSync(this.$bvModal, {text: `삭제: ${this.progCount}건, 실패: ${failCnt}`});
        this.$refs['excelGrid'].clearAllSelected();
        await this.getSalesMasterList();

      } catch (err) {

        console.log(err);
      }
    },

    async amountCalc() {
      // console.log("this.master.price =================> ", this.master.price);
      if (!this.master) return;
      if (!this.master.price) return;

      const discountPrice = (this.master.price - this.master.discountAmount);

      if (discountPrice < 0) return alert("할인금액이 최저가격을 넘습니다. 금액을 다시 확인해주세요.");

      this.master.discountRate = Math.floor((this.master.discountAmount / this.master.price) * 100);
      this.master.amount = (this.master.price - this.master.discountAmount);
    },

  }
}
</script>
